import { map } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { downloadFileFrom } from "@/helpers";
import { debounce } from "lodash";
export default {
    name: "buy-flow",
    computed: {
        ...mapGetters({
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
            productsPdf: "order/productsPdf",
            basket: "basket/basket",
            downloadPdfLoading: "order/downloadPdfLoading",
            productsWithDefaultPrices: "basket/productsWithDefaultPrices",
            currentModal: "popups/currentModal",
        }),
    },
    watch: {
        currentModal(value) {
            if (value === null) {
                this.fetchPromotionData();
            }
        },
    },
    methods: {
        ...mapActions({
            fetchPdf: `order/DOWNLOAD_PDF`,
            getClientProducts: "basket/GET_CLIENT_PRICE_BASKET",
            fetchPromotion: "basket/FETCH_PROMOTION",
        }),
        ...mapMutations({
            changeDefaultPricesPopup: "popups/CHANGE_DEFAULT_PRICES_POPUP",
        }),

        fetchPromotionData: debounce(async function () {
            let payload = {}
            payload.products = map(this.basket, product => ({
                id: product.id,
                product_price_id: product.select_type.id,
                count: product.select_count,
                is_present: product.isPresent || false,
                is_added_from_sale: product.isAddedFromSale || false,
            }));

            this.promotionResponse = await this.fetchPromotion(payload)
        }, 500),

        async showClientPrices() {
            await this.getClientProducts({ ids: map(this.basket, "id") });

            this.changeDefaultPricesPopup(true);
        },
        async downloadPdf() {
            const products = map(this.basket, product => ({
                id: product.id,
                value: product.select_type.value,
                count: product.select_count,
            }));

            await this.fetchPdf({ products });
            await downloadFileFrom(this.productsPdf.path, this.$t('orderForPatient') + ".pdf");
        },
    },
};
